<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-message`"
        v-model="properties.message"
        outlined
        dense
        data-cy="log-message"
        class="required-asterisk"
        :rules="[v => !!v || $lang.labels.required]"
        :label="$lang.labels.message"
        :readonly="!canEdit"
      />
    </v-col>
  </v-row>
</template>

<script>
import StepMixin from './StepMixin'

export default {
  name: 'LogStep',
  mixins: [StepMixin]
}
</script>
